<template>
  <g>
    <g id="g5702" clip-path="url(#clipPath5706)"
      ><path
        d="m 9189,6592.5 c 0,85.8804 -69.1719,155.5 -154.5,155.5 -85.3281,0 -154.5,-69.6196 -154.5,-155.5 0,-85.8804 69.1719,-155.5 154.5,-155.5 85.3281,0 154.5,69.6196 154.5,155.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5708"/><path
        d="m 2158,5987.5 c 0,85.8804 -69.1721,155.5 -154.5,155.5 -85.328,0 -154.5,-69.6196 -154.5,-155.5 0,-85.8804 69.172,-155.5 154.5,-155.5 85.3279,0 154.5,69.6196 154.5,155.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5710"/><g id="g5712" transform="rotate(180,1001.75,3957.75)"
        ><path
          d="M 122.5,0 C 122.5,67.65488 67.65488,122.5 0,122.5 -67.65488,122.5 -122.5,67.65488 -122.5,0 c 0,-67.65488 54.84512,-122.5 122.5,-122.5 67.65488,0 122.5,54.84512 122.5,122.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5714"/></g
      ><g id="g5716" transform="rotate(180,4517.25,3957.75)"
        ><path
          d="M 122.5,0 C 122.5,67.65488 67.65488,122.5 0,122.5 -67.65488,122.5 -122.5,67.65488 -122.5,0 c 0,-67.65488 54.84512,-122.5 122.5,-122.5 67.65488,0 122.5,54.84512 122.5,122.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5718"/></g
      ><g id="g5720" transform="rotate(180,1001.75,2332.25)"
        ><path
          d="M 122.5,0 C 122.5,67.65488 67.65488,122.5 0,122.5 -67.65488,122.5 -122.5,67.65488 -122.5,0 c 0,-67.65488 54.84512,-122.5 122.5,-122.5 67.65488,0 122.5,54.84512 122.5,122.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5722"/></g
      ><g id="g5724" transform="rotate(180,4517.25,2332.25)"
        ><path
          d="M 122.5,0 C 122.5,67.65488 67.65488,122.5 0,122.5 -67.65488,122.5 -122.5,67.65488 -122.5,0 c 0,-67.65488 54.84512,-122.5 122.5,-122.5 67.65488,0 122.5,54.84512 122.5,122.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5726"/></g
      ><g id="g5728" transform="rotate(180,2759.75,4424.75)"
        ><path
          d="M 66.5,0 C 66.5,36.72694 36.72694,66.5 0,66.5 -36.72694,66.5 -66.5,36.72694 -66.5,0 c 0,-36.72694 29.77306,-66.5 66.5,-66.5 36.72694,0 66.5,29.77306 66.5,66.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5730"/></g
      ><g id="g5732" transform="rotate(180,3987.75,4430.75)"
        ><path
          d="M 77.5,0 C 77.5,42.80207 42.80207,77.5 0,77.5 -42.80207,77.5 -77.5,42.80207 -77.5,0"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5734"/></g
      ><g id="g5736" transform="rotate(180,1531.25,4430.75)"
        ><path
          d="M 77.5,0 C 77.5,42.80207 42.80207,77.5 0,77.5 -42.80207,77.5 -77.5,42.80207 -77.5,0"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5738"/></g
      ><path
        d="m 2079,6290.5 c 0,41.6973 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8027 -75.5,-75.5 0,-41.6973 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8027 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5740"/><path
        d="m 9110,6290.5 c 0,41.6973 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8027 -75.5,-75.5 0,-41.6973 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8027 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5742"/><path
        d="m 9112,4664.5 c 0,42.8023 -34.6982,77.5 -77.5,77.5 -42.8018,0 -77.5,-34.6977 -77.5,-77.5 0,-42.8023 34.6982,-77.5 77.5,-77.5 42.8018,0 77.5,34.6977 77.5,77.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5744"/><path
        d="m 2081,4664.5 c 0,42.8023 -34.6979,77.5 -77.5,77.5 -42.8021,0 -77.5,-34.6977 -77.5,-77.5 0,-42.8023 34.6979,-77.5 77.5,-77.5 42.8021,0 77.5,34.6977 77.5,77.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5746"/><path
        d="m 9112,7915.5 c 0,42.8023 -34.6982,77.5 -77.5,77.5 -42.8018,0 -77.5,-34.6977 -77.5,-77.5 0,-42.8023 34.6982,-77.5 77.5,-77.5 42.8018,0 77.5,34.6977 77.5,77.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5748"/><path
        d="m 2081,7915.5 c 0,42.8023 -34.6979,77.5 -77.5,77.5 -42.8021,0 -77.5,-34.6977 -77.5,-77.5 0,-42.8023 34.6979,-77.5 77.5,-77.5 42.8021,0 77.5,34.6977 77.5,77.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5750"/><path
        d="M 9261,4286 H 1776"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5752"/><g id="g5754" transform="rotate(180,888.25,2200.25)"
        ><path
          d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5756"/></g
      ><path
        d="m 9261.5,4287 c 62.6846,0 113.5,50.8159 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5758"/><path
        d="M 3217,10146 H 7820"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5760"/><path
        d="M 8130,10146 H 9261"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5762"/><path
        d="M 1776,10146 H 2907"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5764"/><g
        id="g5766"
        transform="matrix(-0.9428,0.33337,-0.33337,-0.9428,1776.5,10032.5)"
        ><path
          d="m 113.5,0 c 0,12.88955 -2.19557,25.68493 -6.49252,37.83715"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5768"/></g
      ><g id="g5770" transform="rotate(90,-4128,5904.5)"
        ><path
          d="m 113.5,0 c 0,48.09788 -30.3163,90.97323 -75.66285,107.00748"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5772"/></g
      ><path
        d="M 9368.5078,10070.337 C 9352.4736,10115.684 9309.5977,10146 9261.5,10146"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5774"/><path
        d="m 9375,10032.5 c 0,12.89 -2.1953,25.685 -6.4922,37.837"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5776"/><path
        d="m 1663,4400 v 5632"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5778"/><path
        d="M 9374,10032 V 4400"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5780"/><path
        d="M 9367,10070 H 8130 V 8995"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5782"/><path
        d="M 8052.7456,8860.8301 C 8100.5508,8888.6074 8130,8939.9434 8130,8995.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5784"/><g
        id="g5786"
        transform="matrix(0.86609,-0.49989,0.49989,0.86609,7975.5,8995.5)"
        ><path
          d="M -133.80339,-77.7457 C -106.20527,-125.85999 -55.1993,-155.5 0,-155.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5788"/></g
      ><path
        d="m 7820,8995 v 1075 H 3217 V 8995"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5790"/><path
        d="M 3139.7456,8860.8301 C 3187.5505,8888.6074 3217,8939.9434 3217,8995.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5792"/><g
        id="g5794"
        transform="matrix(0.86604,-0.49997,0.49997,0.86604,3062.5,8995.5)"
        ><path
          d="M -133.80339,-77.7457 C -106.20527,-125.85999 -55.1993,-155.5 0,-155.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path5796"/></g
      ><path
        d="m 2907,8995 v 1075 H 1670"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5798"/><path
        d="m 7820,10146 v -76"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5800"/><path
        d="m 8130,10070 v 76"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5802"/><path
        d="m 2907,10146 v -76"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5804"/><path
        d="m 3217,10070 v 76"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path5806"/></g
  ></g>
</template>
<script>
export default {
  name: "PressureMa26Bottom",
};
</script>
